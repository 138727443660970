<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-user-manager") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userMgt">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="name">{{ $t("user-data-name") }}</option>
            <option value="userId">{{ $t("user-data-num") }}</option>
            <option value="loginId">{{ $t("user-data-email-id") }}</option>
            <option value="department">{{ $t("user-data-dept-name") }}</option>
            <option value="phone">{{ $t("user-data-phone-num") }}</option>
          </select>
          <input type="text" v-model="keyword" @keyup.enter="search" />

        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("user-data-authority") }}</p>
          <label>
            <input
                type="radio"
                v-model="userTypes"
                value=""
                name="permission"
                checked
            />{{ $t("search-keyword-all") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                v-model="userTypes"
                value="Admin"
                name="permission"
            />{{ $t("user-data-type-admin") }}
          </label>
          <label>
            <input
                type="radio"
                v-model="userTypes"
                value="User"
                name="permission"
            />{{ $t("user-data-type-user") }}
          </label>
        </div>
        <div class="flexC" style="margin-top: 10px;">
          <p class="bold" style="margin-left: 0px;">{{ $t("user-data-status") }}</p>
          <label>
            <input
                type="radio"
                name="status"
                value=""
                v-model="statuses"
                checked
            />{{ $t("search-keyword-all") }}
          </label>
          <label>
            <input
                type="radio"
                name="status"
                value="Waiting"
                v-model="statuses"
            />{{ $t("user-data-status-waiting") }}
          </label>
          <label>
            <input
                type="radio"
                name="status"
                value="Used"
                v-model="statuses"
            />{{ $t("user-data-status-used") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="NotUsed"
                v-model="statuses"
            />{{ $t("user-data-status-not-used") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="Inactive"
                v-model="statuses"
            />{{ $t("user-data-status-inactivate") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="Unsubscribe"
                v-model="statuses"
            />{{ $t("user-data-status-withdrawal") }}
          </label>
        </div>
        <div class="buttonWrap" style="width:100%; display: flex; margin-top: 15px;">
          <button class="point medium" @click="search" style="margin-left: 0px; width: 120px;">{{ $t("btn-searching") }}</button>
          <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
          <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
          <router-link to="userRegister">
            <button class="small right">{{ $t("btn-register") }}</button>
          </router-link>
        </h2>
        <table>
          <tr>
            <th>{{ $t("user-data-num") }}</th>
            <th>{{ $t("user-data-name") }}</th>
            <th>{{ $t("user-data-email-id") }}</th>
            <th>{{ $t("user-data-dept-name") }}</th>
            <th>{{ $t("user-data-phone-num") }}</th>
            <th>{{ $t("user-data-authority") }}</th>
            <th>{{ $t("user-data-app-available") }}</th>
            <th>{{ $t("user-data-status") }}</th>
            <th>{{ $t("user-data-regdate") }}</th>
            <th>{{ $t("user-data-last-login-date") }}</th>
          </tr>
          <colgroup>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 8%;"/>
            <col style="width: 10%;"/>
            <col style="width: 8%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="9" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in userList" :key="i">
            <td>{{ data.userId }}</td>
            <td>
              <a @click="handleDetail(data.userId)" class="link">{{
                  data.name
                }}</a>
            </td>
            <td>{{ data.loginId }}</td>
            <td>
              {{ data.departmentInfo.name }}
            </td>
            <td>{{ getMask(data.phone) }}</td>
            <td>
              {{
                data.userType == "User"
                    ? $t("user-data-type-user")
                    : data.userType == "Admin"
                    ? $t("user-data-type-admin")
                        : "-"
              }}
            </td>
             <td>
              {{
                data.isViewerAppAuth && data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-viewer") + ", " + $t("user-data-app-streamer")
                  : data.isViewerAppAuth && data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-viewer")
                  : data.isViewerAppAuth && !data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-viewer") + ", " + $t("user-data-app-streamer")
                  : !data.isViewerAppAuth && data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-streamer")
                  : data.isViewerAppAuth && !data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-viewer")
                  : !data.isViewerAppAuth && data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-controller")
                  : !data.isViewerAppAuth && !data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-streamer")
                  : "-"
              }}

            </td>

            <td>
              {{
                data.status == "Used"
                    ? $t("user-data-status-used")
                    : data.status == "Waiting"
                    ? $t("user-data-status-waiting")
                    : data.status == "NotUsed"
                        ? $t("user-data-status-not-used")
                        : data.status == "Inactive"
                            ? $t("user-data-status-inactivate")
                            : data.status == "Unsubscribe"
                                ? $t("user-data-status-withdrawal")
                                : ""
              }}
            </td>
            <td>
              {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
            <td>
              {{
                data.loginDate == null
                    ? "--"
                    : moment(data.loginDate).format("YYYY.MM.DD HH:mm:ss")
              }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "currentPage + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchUserList } from "@/api/user";
import { format } from "@/mixins/format";
import {mapState} from "vuex";
export default {
  components: { Datepicker },
  mixins: [format],
  name: "UserList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      userList: [],
      total: 0,
      keyword: null,
      keywordType: "all",
      statuses: "",
      userTypes: "",
      currentPage: 0,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 0 });
    this.preset();


  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
    })
  },
  methods: {
    preset(){
      var localStorageData = localStorage.getItem("user_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.currentPage = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("user_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getUserList();
    },
    reset() {
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "userRegister", query: { userId: id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val  - 1;
      this.getUserList();
    },
    getUserList() {
      var sendUserType = this.userTypes;
      var sendStatuses = this.statuses;

      if(!this.isAdmin){
        if(sendUserType == null || sendUserType == ""){
          sendUserType = 'Manager,User';
        }
        if(sendStatuses == null || sendStatuses == ""){
          sendStatuses = 'Waiting,Used';
        }
      }


      let params = {
        pageNumber: this.currentPage, // pagination
        pageSize: 10, // pagination
        keywordType: this.keywordType,
        keyword: this.keyword,
        userTypes: sendUserType,
        statuses: sendStatuses,
      };
      params["dateRange.from"] = null;
      params["dateRange.to"] = null;

      if (this.startYmd != null&& this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }

      this.persist(params);
      fetchUserList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.userList = res.data.data.content;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.getUserList();
    }
  },
};
</script>
